header {
  padding-bottom: 1em;

  .background {
    width: 100%;
    height: 100%;
    height: calc(100% - 1px);
    background-image: url("https://cms.pfadfinder-sendenhorst.de/wp-content/uploads/2020/01/header_winter.jpg");
    background-size: cover;
  }

  .navbar {
    padding: .4rem 1rem;
  }

  .navbar-light .navbar-nav .nav-link {
    font-size: .9em;
    color: $brand-primary;

    &:hover {
      color: $gray-800;
    }

    &:focus {
      outline: none;
    }
  }
}

.bg-blue-light {
  background-color: $blue-light;

  &.navbar-toggler {
    background-color: $blue-light;
  }
}

.logo {
  max-height: 230px;
}

// animation for logo
.pull-down{
  transform-origin: 50% 0%;
  animation-name: pull-down;
  animation-duration: 1.1s;
  animation-timing-function: ease-out;
}

@keyframes pull-down {
  0% {
    transform: scaleY(.1);
  }
  40% {
    transform: scaleY(1.02);
  }
  60% {
    transform: scaleY(.98);
  }
  80% {
    transform: scaleY(1.01);
  }
  100% {
    transform: scaleY(.98);
  }
  80% {
    transform: scaleY(1.01);
  }
  100% {
    transform: scaleY(1);
  }
}
