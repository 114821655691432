$google: #dd4b39 !default;
$youtube: #f00 !default;
$pinterest: #bd081c !default;
$facebook: #3b5998 !default;
$twitter: #00aced !default;
$instagram: #bc2a8d !default;

footer {
  margin-top: 2.5em;

  .full {
    width: 100%;
  }

  .newsletter-box {
    overflow: hidden;

    .btn {
      width: 100%;
      height: 2.6em;
    }
  }

  .footer-bottom {
    padding-top: .8em;
    padding-bottom: .8em;
    overflow: hidden;
    color: $beige;
    background: darken($brown, 5%);

    .copyright a {
      color: $beige;
    }

    p {
      padding-top: .6em;
    }
  }
}

.footer {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 2em;
  background: $brown;

  p {
    margin: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h3 {
    padding: 2.6em 0 .6em;
    font-size: 1em;
    font-weight: 600;
    line-height: 2em;
    color: $brand-primary;
    text-transform: uppercase;
    border-bottom: 1px solid $gray-800;
  }

  ul {
    padding-left: 0;
    margin-top: .8em;
    margin-left: 0;
    font-size: .8em;
    color: $black;
    list-style-type: none;
  }

  a,
  i {
    color: $beige;
  }

  li {
    a {
      display: block;
      padding: 0 0 .3em 0;

      &:hover {
        color: $beige;
        text-decoration: underline;
      }
    }
  }

  .icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
  }

  .feed {
    &:not(:first-child) {
      border-top: 1px dashed $beige;
    }

    a {
      padding: .3em 0;
    }
  }

  .newsletter-box {
    font-family: "Shadows Into Light", cursive;

    .mail {
      mask: url("../images/icons/mail.svg") no-repeat 0 0/20px 20px;
      background-color: $white;
    }
  }
}

.social {
  li {
    float: right;
    width: 3em;
    height: 3em;
    padding: 0;
    margin: 0 .6em 0 0;
    line-height: 3em;
    text-align: center;
    background: none repeat scroll 0 0 $gray-600;
    border: 1px solid $gray-600;
    border-radius: 50%;
    transition: all .5s ease 0s;

    a,
    i {
      color: $gray-400;

      &:hover {
        color: $gray-400;
      }
    }
  }

  .google {
    &:hover {
      background: $google;
      border: 1px solid $google;
      transform: scale(1.15) rotate(360deg);
    }
  }

  .youtube {
    &:hover {
      background: $youtube;
      border: 1px solid $youtube;
      transform: scale(1.15) rotate(360deg);
    }
  }

  .pinterest {
    &:hover {
      background: $pinterest;
      border: 1px solid $pinterest;
      transform: scale(1.15) rotate(360deg);
    }
  }

  .facebook {
    &:hover {
      background: $facebook;
      border: 1px solid $facebook;
      transform: scale(1.15) rotate(360deg);
    }
  }

  .twitter {
    &:hover {
      background: $twitter;
      border: 1px solid $twitter;
      transform: scale(1.15) rotate(360deg);
    }
  }

  .instagram {
    &:hover {
      background: $instagram;
      border: 1px solid $instagram;
      transform: scale(1.15) rotate(360deg);
    }
  }
}
