.table-responsive {
  &.calendar-responsive {
    padding: 1em;
    background-color: $white;
    border-radius: 0 0 .25rem .25rem;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .08);
  }

  .calendar {
    color: $gray-800;
    background: $white;

    td {
      padding: .3em .65em;
      text-align: center;
      border: none;
    }

    thead {
      font-weight: 700;
      color: $red;
      text-transform: uppercase;
    }

    tbody td:hover {
      color: $brown;
      background: $gray-600;
    }
  }
}

.calendar-container {
  position: relative;
  width: 100%;
  margin-top: 1.5em;

  header {
    padding: 3em 2em;
    color: $white;
    background: $red;
    border-radius: .25rem .25rem 0 0;
  }
}

.day {
  font-size: 8em;
  font-weight: 900;
  line-height: 1em;
}

.month {
  font-size: 4em;
  line-height: 1em;
  //text-transform: lowercase;
}

.current-day {
  color: $red;
}

.prev-month,
.next-month {
  color: $gray-600;
}

.ring-left,
.ring-right {
  position: absolute;
  top: 265px;
}

.ring-left { left: 2em; }
.ring-right { right: 2em; }

.ring-left::before,
.ring-left::after,
.ring-right::before,
.ring-right::after {
  display: inline-block;
  width: 8px;
  height: 32px;
  margin: 8px;
  content: "";
  background: $white;
  border-radius: 4px;
  box-shadow: 0 3px 1px rgba(0, 0, 0, .3), 0 -1px 1px rgba(0, 0, 0, .2);
}
