.rc-text {
  margin-top: 1rem;
}

.rc-anchor {
  background: #f9f9f9;
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, .08);
}

.rc-anchor-normal-footer {
  display: inline-block;
  width: 70px;
  height: 74px;
  vertical-align: top;

  &.smalltext {
    .rc-anchor-pt {
      font-size: 5px;
      line-height: 6px;
    }
  }
}

.rc-anchor-logo-img {
  width: 40px;
  height: 40px;
  margin: 5px 15px 0 15px;
  background: url("https://www.gstatic.com/recaptcha/api2/logo_48.png");
  background-repeat: no-repeat;
  background-size: 40px;
  transition: all .3s ease;
}

.rc-anchor-pt {
  background-image: url("data:image/png;base64,iVBORw0KGgBoL6Dvu4FBZS7AsBUBZbcE0EjRBIuz9parvgrEmX/4MVE0L7QBTRCClqBDowGmyGhHCofEBOS1SOx60QPIbNRzukVUBw2TRTMzFBHWm8dot667QBwOIHpx+v6soBGF2CnpqISiEBuQvV/y/F8p3PIQBIGTOP9F5/dNyZiGzogBJcXEt1EWJH94pSxA088QY5YBWiaNaZJFBR8ByAQR+rC81yvCcFpMXMsBOaVCglLDuABdWrzBf9ldLSJs4Lp3FwBnQzn7CzeSOI3Qy0B9EIXDFzB5QLGudu775/sBNP9pBoXIhPQmJUKAHBXzzDzwBE3o+8tqsVKZcZrkLa7dg/gBICndwcwApDT5FAWspwBD4XRPGPTfAB2xVffRPFB4subgSnFB2jFY8DjyibeD+E1Z");

  a {
    color: $gray-800;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.rc-anchor-aria-status {
  display: none;
}

.rc-anchor-invisible {
  display: flex;
  float: right;
  width: 70px;
  height: 60px;
  margin-right: -1.25rem;
  overflow: hidden;
  transition: .3s ease;

  &:hover {
    width: 256px;
    transition: .3s ease;
  }

  .rc-anchor-normal-footer {
    .rc-anchor-pt {
      width: 70px;
      margin-top: 2px;
      text-align: center;
      transition: opacity .3s ease;
    }
  }

  .rc-anchor-pt {
    font-family: Roboto, helvetica, arial, sans-serif;
    font-size: 8px;
    font-weight: 400;
  }
}

.rc-anchor-invisible-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  padding: 0 16px;
  font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: $white;
  white-space: nowrap;
  background: #4a90e2;

  strong {
    font-weight: 500;
  }

  &.smalltext {
    min-width: 186px;
    padding: 0 10px;
    font-size: 12px;
    line-height: 16px;
    white-space: normal;

    .rc-anchor-pt {
      line-height: 12px;
      white-space: normal;

      a:link {
        font-size: 9px;
      }
    }
  }

  .rc-anchor-pt {
    transition: opacity .3s ease;

    a:link,
    a:visited {
      font-size: 10px;
      color: $white;
    }
  }
}

.rc-anchor-invisible-hover:hover {
  .rc-anchor-logo-img {
    width: 44px;
    height: 44px;
    margin: 8px 13px 0 13px;
    background-size: 44px;
  }

  .rc-anchor-normal-footer {
    .rc-anchor-pt {
      opacity: 0;
    }
  }

  .rc-anchor-invisible-text {
    .rc-anchor-pt {
      opacity: 1;
    }
  }
}
