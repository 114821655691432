.container {
  &.content {
    min-height: 40vh;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

@media screen and (max-width: 785px) {
  .rc-badge {
    display: none;
  }
}

@media screen and (min-width: 786px) {
  .rc-text {
    display: none;
  }
}
