.yellow {
  color: $yellow;
}

.orange {
  color: $orange;
}

.blue-light {
  color: $blue-light;
}

.blue {
  color: $blue-cat;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.gray {
  color: $gray-500;
}

.count-title {
  margin-top: 10px;
  margin-bottom: 0;
  font-family: "Press Start 2P", cursive;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
}

.odd {
  background-color: rgba(0, 0, 0, .05);
}
