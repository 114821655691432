.material-background {
  position: absolute;
  top: calc(296px - 1em);
  left: 0;
  z-index: 0;
  display: block;
  width: 100%;
  height: 12em;
  background-color: #fff;
  transition: all ease 1s;

  &.allgemein {
    background-color: $gray-900;
  }

  &.wichtel {
    background-color: $yellow;
  }

  &.woelflinge {
    background-color: $orange;
  }

  &.pfadis {
    background-color: $blue-light;
    border-top: 1px solid $blue;
  }

  &.jungpfadfinder {
    background-color: $blue-cat;
  }

  &.caravelles,
  &.pfadfinder {
    background-color: $green;
  }

  &.ranger,
  &.rover {
    background-color: $red;
  }
}

@media screen and (max-width: 786px) {
  .material-background {
    top: calc(319px - 1em);
  }
}

.mb-6 {
  position: relative;
  margin-top: 2em;
  margin-bottom: 3.5em;
  color: $white;

  h1 {
    margin-bottom: .5em;
    font-family: "Shadows Into Light", cursive;
    font-weight: 900;
  }

  p {
    min-height: 1.5em;
    font-size: .8em;
  }
}

.badge-allgemein {
  color: $white;
  background-color: $gray-900;
}

.badge-wichtel {
  color: $white;
  background-color: $yellow;
}

.badge-woelflinge {
  color: $white;
  background-color: $orange;
}

.badge-pfadis {
  color: $white;
  background-color: $blue-light;
}

.badge-jungpfadfinder {
  color: $white;
  background-color: $blue-cat;
}

.badge-caravelles,
.badge-pfadfinder {
  color: $white;
  background-color: $green;
}

.badge-ranger,
.badge-rover {
  color: $white;
  background-color: $red;
}
