html,
body,
.st-container,
.st-pusher,
.st-content {
  height: 100%;
}

.st-content {
  overflow-x: hidden;
  overflow-y: scroll;
  background: $beige;
}

.st-content,
.st-content-inner {
  position: relative;
}

.st-container {
  position: relative;
  overflow: hidden;
}

.st-pusher {
  position: relative;
  left: 0;
  z-index: 99;
  height: 100%;
  transition: transform .5s;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: "";
    background: rgba($black, .2);
    opacity: 0;
    transition: opacity .5s, width .1s .5s, height .1s .5s;
  }
}

.st-menu-open {

  .st-pusher{

    &::after {
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity .5s;
    }
  }

  .st-menu {

    &::after{
      width: 0;
      height: 0;
      opacity: 0;
      transition: opacity .5s, width .1s .5s, height .1s .5s;
    }
  }
}

.st-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  visibility: hidden;
  background: $blue-light;
  transition: all .5s;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba($black, .2);
    opacity: 1;
    transition: opacity .5s;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {

    a {
      position: relative;
      display: block;
      padding: 1em 2.5em 1em 1.5em;
      font-weight: 400;
      color: $brand-primary;
      letter-spacing: 1px;
      outline: none;
      box-shadow: inset 0 -1px rgba($brand-primary, .2);
      transition: background .3s, box-shadow .3s;

      &:hover,
      [data-submenu]:hover::after,
      [data-submenu]:focus::after {
        color: $navbar-light-hover-color;
        text-decoration: none;
      }
    }

    //&:first-child {
    //
    //  a{
    //    box-shadow: inset 0 -1px rgba( $brand-primary, .2 ), inset 0 1px rgba( $brand-primary, .2 );
    //  }
    //}
  }

  h2 {
    padding: .8em;
    margin: 0;
    font-family: "Shadows Into Light", cursive;
    font-size: 2em;
    font-weight: 300;
    color: inherit;
    text-shadow: 0 0 1px rgba($black, .1);
  }
}

.st-effect-7 {
  &.st-container {
    perspective: 1500px;
    perspective-origin: 0% 50%;
  }

  .st-pusher {
    transform-style: preserve-3d;
  }

  &.st-menu-open {
    .st-pusher {
      transform: translate3d(300px, 0, 0);
    }

    .st-effect-7.st-menu {
      visibility: visible;
      transition: transform .5s;
      transform: translate3d(-100%, 0, 0) rotateY(0deg);
    }
  }

  &.st-menu {
    transform: translate3d(-100%, 0, 0) rotateY(-90deg);
    transform-origin: 100% 50%;
    transform-style: preserve-3d;
  }
}

.custom-toggler.navbar-toggler-icon {
  mask: url("../images/icons/burger_menu.svg") no-repeat 0 0/30px 30px;
  background-color: $brand-primary;
}

.st-trigger-effects {
  margin: 1em 0;
}

.menu-breadcrumbs {
  position: relative;
  padding: 0 2em 2em 2em;
  font-size: .65em;
  line-height: 1;
  color: $brand-primary;

  a:hover {
    color: $navbar-light-hover-color;
    text-decoration: none;
  }
}

.icon {
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.icon--arrow-left::before {
  content: "";
}

.icon--menu::before {
  content: "";
}

.icon--cross::before {
  content: "";
}


/* Menu styles */

.menu {
  position: fixed;
  top: 120px;
  left: 0;
  width: 300px;
  height: calc(100vh - 120px);
  background: #1c1d22;
}

.menu__wrap {
  position: absolute;
  top: 3.5em;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

.menu__level {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  overflow-y: scroll;
  list-style-type: none;
  visibility: hidden;

  &:not([class*=" menu_level_"]) {
    width: calc(100% + 50px);
  }

  &[class*=" menu_level_"] {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
}

.menu__level--current {
  visibility: visible;
}

.menu__item {
  display: block;
  width: calc(100% - 50px);
}

.menu__link[data-submenu]::after {
  position: absolute;
  right: 0;
  bottom: 40%;
  // align vertically
  padding: .5em 1em;
  content: "";
  mask: url("../images/icons/ellipses.svg") no-repeat 0 0/20px 20px;
  background-color: $brand-primary;
}

.menu__link--current::before {
  position: absolute;
  top: 50%;
  left: .5em;
  height: 4px;
  font-size: 1.5em;
  line-height: 0;
  content: "";
}

[class^="animate-"],
[class*=" animate-"] {
  visibility: visible;
}

.animate-outToRight .menu__item {
  animation: outToRight .6s both cubic-bezier(.7, 0, .3, 1);
}

@keyframes outToRight {
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate-outToLeft .menu__item {
  animation: outToLeft .6s both cubic-bezier(.7, 0, .3, 1);
}

@keyframes outToLeft {
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate-inFromLeft .menu__item {
  animation: inFromLeft .6s both cubic-bezier(.7, 0, .3, 1);
}

@keyframes inFromLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate-inFromRight .menu__item {
  animation: inFromRight .6s both cubic-bezier(.7, 0, .3, 1);
}

@keyframes inFromRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.menu__breadcrumbs {
  position: relative;
  padding: 2.5em 3.75em 1.5em 2.5em;
  font-size: .65em;
  line-height: 1;

  a {
    display: inline-block;
    font-weight: 700;
    color: $brand-primary;
    text-transform: uppercase;
    letter-spacing: 1px;
    vertical-align: middle;
    cursor: pointer;

    &:last-child {
      pointer-events: none;
    }

    &:hover,
    &:focus {
      color: $navbar-light-hover-color;
      text-decoration: none;
    }

    &:not(:last-child) {
      &::after {
        display: inline-block;
        padding: 0 .5em;
        color: $navbar-light-hover-color;
        content: "/";
      }

      &:hover::after,
      &:focus::after {
        color: $navbar-light-hover-color;
      }
    }
  }
}

.menu__back {
  position: absolute;
  top: 0;
  right: 2.25em;
  z-index: 100;
  padding: 1.365em .65em 0 0;
  margin: 0;
  font-size: 1.05em;
  color: $gray-800;
  cursor: pointer;
  background: none;
  border: none;

  &:hover,
  &:focus {
    color: $white;
    outline: none;
  }
}

.menu__back--hidden {
  pointer-events: none;
  opacity: 0;
}

@media screen and (max-width: 40em) {
  .menu {
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    transition: transform .3s;
    transform: translate3d(-100%, 0, 0);
  }
  .menu--open {
    transform: translate3d(0, 0, 0);
  }
}
