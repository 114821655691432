.card-custom {
  margin-top: 1.5em;
  border: none;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .08);

  &.post{
    .card-title {
      text-align: left;
    }

    .post-meta {
      display: inline;
      padding: .5em;
      font-family: "Shadows Into Light", cursive;
      color: $brand-primary;
    }

    .icon {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      vertical-align: middle;
      content: "";
    }

    .calendar-clock {
      mask: url("../images/icons/calendar_clock.svg") no-repeat 0 0/20px 20px;
      background-color: $brand-primary;
    }

    .user {
      mask: url("../images/icons/user.svg") no-repeat 0 0/20px 20px;
      background-color: $brand-primary;
    }

    .tag {
      mask: url("../images/icons/tag.svg") no-repeat 0 0/20px 20px;
      background-color: $brand-primary;
    }

    .content {
      margin-top: 2em;

      .wp-block-separator {
        display: block;
        width: 100%;
        border: 0;
      }

      .download {
        img {
          margin: 0;
        }
      }

      img {
        margin: 1em;
      }
    }
  }

  &.event {
    .card-title {
      text-align: center;
    }
  }

  &.download {
    .content {
      img {
        margin: 0;
      }
    }
  }

  .card-date {
    position: absolute;
    top: 0;
    right: 15px;
    padding: 3px 8px;
    font-size: .6em;
    color: $white;
    background-color: $brand-primary;
    border-radius: 0 0 .3em .3em;
  }

  .card-title {
    font-family: "Shadows Into Light", cursive;
  }

  .info {
    font-size: .8em;
    color: $gray-600;
  }

  p {
    font-size: .95em;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.card-img {
  display: block;
}
