.cal-icon {
  position: relative;
  width: 4em;
  padding-top: 5px;
  font: 400 20px/40px "Arial Black", Arial, Helvetica, sans-serif;
  color: $gray-800;
  text-align: center;
  text-shadow: $white 0 1px 0;
  background: $gray-200;
  border-radius: 3px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .08);

  &.start {
    float: left;
    margin: .25em 10px 10px 0;
  }

  &.end {
    float: right;
    margin: .25em 0 10px 10px;
  }

  &.single{
    margin: .25em auto 10px auto;
  }

  &::before,
  &::after {
    position: absolute;
    top: 5px;
    z-index: 1;
    float: left;
    width: 8px;
    height: 8px;
    content: "";
    background: $white;
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .3), 0 -1px 1px rgba(0, 0, 0, .2);
  }

  &::before {
    left: 11px;
  }

  &::after {
    right: 11px;
  }

  em {
    display: block;
    font: normal 400 11px/23px Arial, Helvetica, sans-serif;
    color: $white;
    text-shadow: $red 0 -1px 0;
    background: $red;
    border-top: 1px solid $red;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    &::before,
    &::after {
      position: absolute;
      top: -5px;
      z-index: 2;
      float: left;
      width: 4px;
      height: 14px;
      content: "";
      background: $gray-300;
      background: -webkit-gradient(linear, left top, left bottom, from($gray-200), to($gray-400));
      border-radius: 2px;
    }

    &::before {
      left: 13px;
    }

    &::after {
      right: 13px;
    }
  }
}

.row-event {
  margin: 1em 0;
}
